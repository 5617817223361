import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grossprojekte',
  templateUrl: './grossprojekte.component.html',
  styleUrls: ['./grossprojekte.component.scss']
})
export class GrossprojekteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
