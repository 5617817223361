import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mehrfamilien',
  templateUrl: './mehrfamilien.component.html',
  styleUrls: ['./mehrfamilien.component.scss']
})
export class MehrfamilienComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
