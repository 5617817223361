import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NavigationComponent} from './navigation/navigation.component';
import {FreibauComponent} from './freibau/freibau.component';
import {SliderComponent} from './slider/slider.component';
import {PortfolioComponent} from './portfolio/portfolio.component';
import {AktuellesComponent} from './aktuelles/aktuelles.component';
import {ContactComponent} from './contact/contact.component';
import {OeffentlicheComponent} from './oeffentliche/oeffentliche.component';
import {GrossprojekteComponent} from './grossprojekte/grossprojekte.component';
import {MehrfamilienComponent} from './mehrfamilien/mehrfamilien.component';
import {EinfamilienComponent} from './einfamilien/einfamilien.component';

const routes: Routes = [
  { path: '', component: SliderComponent },
  { path: 'freibau', component: FreibauComponent },
  { path: 'portfolio', component: PortfolioComponent},
  { path: 'aktuelles', component: AktuellesComponent},
  { path: 'kontakt', component: ContactComponent},
  { path: 'einfamilien', component: EinfamilienComponent},
  { path: 'mehrfamilien', component: MehrfamilienComponent},
  { path: 'grossprojekte', component: GrossprojekteComponent},
  { path: 'oeffentlichebauten', component: OeffentlicheComponent},
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [FreibauComponent];
