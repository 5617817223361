import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})


export class NavigationComponent implements OnInit {
  status = false;

  constructor() {
  }

  ngOnInit() {




  }

/*
  clickEvent() {
    const burger = document.querySelector('.menu-btn');
    const nav = document.querySelector('.nav-links');
    const containerLink = document.querySelector('.nav-buttons');
    const mainNav = document.querySelector('.navigation');

    // tslint:disable-next-line:only-arrow-functions
    burger.addEventListener('click', function() {
      nav.classList.toggle('nav-active');
      mainNav.classList.toggle('navigation-active');
      containerLink.classList.toggle('nav-buttons-active');
    });
    return;
  }
*/


/*  burgerAnimation() {
    const menuBtn = document.querySelector('.menu-btn');
    let menuOpen = false;
    // tslint:disable-next-line:only-arrow-functions
    menuBtn.addEventListener('click', function() {
      if (!menuOpen) {
        menuBtn.classList.add('open');
        menuOpen = true;
      } else {
        menuBtn.classList.remove('open');
        menuOpen = false;
      }
    });
    return;
  }*/
}
