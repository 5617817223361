import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FreibauComponent } from './freibau/freibau.component';
import { SliderComponent } from './slider/slider.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { AktuellesComponent } from './aktuelles/aktuelles.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ContactComponent } from './contact/contact.component';
import { EinfamilienComponent } from './einfamilien/einfamilien.component';
import { MehrfamilienComponent } from './mehrfamilien/mehrfamilien.component';
import { OeffentlicheComponent } from './oeffentliche/oeffentliche.component';
import { GrossprojekteComponent } from './grossprojekte/grossprojekte.component';
import { FooterComponent } from './footer/footer.component';


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    FreibauComponent,
    SliderComponent,
    LandingpageComponent,
    AktuellesComponent,
    PortfolioComponent,
    ContactComponent,
    EinfamilienComponent,
    MehrfamilienComponent,
    OeffentlicheComponent,
    GrossprojekteComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
