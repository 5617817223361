import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-einfamilien',
  templateUrl: './einfamilien.component.html',
  styleUrls: ['./einfamilien.component.scss']
})
export class EinfamilienComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
